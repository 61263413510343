import { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { LuPencil } from "react-icons/lu";
import { ModalBody, ModalHeader, Button, InputOtp } from "@nextui-org/react";
import supabase from "~/utils/supabase";
import posthog from "posthog-js";

interface FormValues {
  otp: string;
}

export default function OtpForm({
  phone,
  onSuccess,
  setStep,
}: {
  phone: string;
  onSuccess: (userProfileExists: boolean) => void;
  setStep: (step: "mobile" | "otp" | "onboarding" | "success") => void;
}) {
  const { control, handleSubmit, resetField } = useForm<FormValues>({
    defaultValues: { otp: "" },
  });

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendOtp = useCallback(async (phoneNumber: string) => {
    try {
      setLoading(true);
      const { error } = await supabase.functions.invoke("auth", {
        body: {
          operation: "resend",
          payload: {
            phone_number: `91${phoneNumber}`,
          },
        },
      });
      if (error) {
        throw error;
      }
      setTimer(60);
    } catch (e: unknown) {
      setErrorMessage((e as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setLoading(true);

        const {
          data: { session },
          error,
        } = await supabase.auth.verifyOtp({
          phone: `91${phone}`,
          token: values.otp,
          type: "sms",
        });

        if (error) {
          throw error;
        }

        if (!session) {
          throw new Error("Session not found");
        }
        const { data: profile } = await supabase
          .from("user_profiles")
          .select("*")
          .eq("id", session.user.id)
          .single()
          .throwOnError();

        if (!profile) {
          throw new Error("Unknown error occured!");
        }

        if (profile.name && profile.name.length > 0) {
          onSuccess(true);
        } else {
          onSuccess(false);
        }
      } catch (e: unknown) {
        setErrorMessage((e as Error).message);
        resetField("otp");
      } finally {
        setLoading(false);
      }
    },
    [onSuccess, phone, resetField]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader className="flex flex-col">
        <h2 className="text-xl font-bold">Verify</h2>
        <div className="flex justify-between">
          <p className="flex-1 text-sm font-normal text-default-500 mb-4">
            Enter the OTP sent to your mobile number <br />
            <span className="text-primary">+91-{phone}</span>
          </p>
          <Button
            onPress={() => setStep("mobile")}
            variant="faded"
            isIconOnly
            size="sm"
            className="ml-2 flex-shrink-0"
          >
            <LuPencil className="h-4 w-4" />
          </Button>
        </div>
      </ModalHeader>

      <ModalBody className="">
        <Controller
          name="otp"
          control={control}
          rules={{
            required: "OTP is required",
            pattern: { value: /^\d{6}$/, message: "Invalid OTP" },
          }}
          render={({ field }) => (
            <InputOtp
              length={6}
              value={field.value}
              autoFocus
              fullWidth
              containerClassName="flex justify-center gap-2 mx-auto"
              onValueChange={field.onChange}
              onComplete={() => handleSubmit(onSubmit)()}
            />
          )}
        />

        {errorMessage && (
          <p className="text-sm text-center text-danger">{errorMessage}</p>
        )}

        <div className="mt-4 flex justify-between items-center">
          <span className="text-sm text-default-500">
            {timer > 0 ? `Resend OTP in ${timer}s` : "Didn't receive OTP?"}
          </span>
          <Button
            size="sm"
            variant="flat"
            color="primary"
            onPress={() => {
              posthog.capture("resend_otp");
              handleResendOtp(phone);
            }}
            isDisabled={timer > 0}
            isLoading={loading}
          >
            Resend OTP
          </Button>
        </div>
      </ModalBody>
    </form>
  );
}
