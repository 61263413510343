import { Modal, ModalContent } from "@nextui-org/react";
import { ReactNode, useState } from "react";
import { Link } from "@nextui-org/link";
import MobileForm from "./mobile-form";
import OtpForm from "./otp-form";
import OnboardingForm from "./onboarding-form";
import LoginSuccess from "./login-success";
import posthog from "posthog-js";

// const HeaderBanner = () => {
//   const texts = [
//     "Unlock full potential",
//     "Enjoying the experience?",
//     "Complete your profile",
//   ];
//   const [currentTextIndex, setCurrentTextIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
//     }, 2500);

//     return () => clearInterval(interval);
//   }, [texts.length]);

//   return (
//     <div className="bg-primary text-white p-4 rounded-t-lg">
//       <div className="flex items-center justify-between">
//         <div
//           className="flex items-center space-x-2"
//           style={{
//             animation:
//               "2.5s ease-in-out 0s infinite normal none running transitionUp",
//           }}
//         >
//           <LuLock />
//           <span className="font-bold overflow-hidden inline-block">
//             {texts[currentTextIndex]}
//           </span>
//         </div>
//       </div>
//       <style jsx>{`
//         @keyframes transitionUp {
//           0% {
//             transform: translateY(120%);
//           }
//           15% {
//             transform: translateY(0px);
//           }
//           80% {
//             transform: translateY(0px);
//           }
//           95% {
//             transform: translateY(-110%);
//           }
//           100% {
//             transform: translateY(-120%);
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

type LoginFlowProps = {
  isOpen: boolean;
  onOpenChange: () => void;
  onSuccess: () => void;
  header?: ReactNode;
};

export default function LoginDialog({
  isOpen,
  onOpenChange,
  onSuccess,
  header,
}: LoginFlowProps) {
  const [step, setStep] = useState<"mobile" | "otp" | "onboarding" | "success">(
    "mobile"
  );
  const [phone, setPhone] = useState<string>("");

  const closeModal = () => {
    if (step === "mobile" || step === "otp") {
      posthog.capture("login_cancelled", { step });
    }

    setStep("mobile");
    setPhone("");
    onOpenChange();
  };

  return (
    <Modal
      isOpen={isOpen}
      backdrop="blur"
      onOpenChange={closeModal}
      placement="bottom-center"
      isDismissable={step == "onboarding" ? false : true}
      hideCloseButton={true}
    >
      <ModalContent>
        {header && <div className="p-6 border-b">{header}</div>}
        {step === "mobile" && (
          <MobileForm
            onSuccess={(phone: string) => {
              setStep("otp");
              setPhone(phone);
            }}
          />
        )}
        {step === "otp" && (
          <OtpForm
            phone={phone}
            setStep={setStep}
            onSuccess={(userProfileExists: boolean) => {
              if (userProfileExists) {
                setStep("success");
              } else {
                setStep("onboarding");
              }
            }}
          />
        )}
        {step === "onboarding" && (
          <OnboardingForm
            onSuccess={() => {
              posthog.capture("onboarding_completed");
              setStep("success");
            }}
          />
        )}
        {step === "success" && (
          <LoginSuccess
            onSuccess={() => {
              closeModal();
              onSuccess();
            }}
          />
        )}
        <p className="text-center text-sm p-4 border-t">
          By continuing, you agree to our{" "}
          <Link target="_blank" href="/terms" size="sm">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link target="_blank" href="/privacy" size="sm">
            Privacy Policy
          </Link>
        </p>
      </ModalContent>
    </Modal>
  );
}
