import { useCallback, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "@nextui-org/react";
import { useUser } from "~/stores/user";
import supabase from "~/utils/supabase";
import posthog from "posthog-js";

interface FormValues {
  name: string;
}

export default function OnboardingForm({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { name: "" },
  });

  const { user } = useUser();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: FormValues) => {
    handleUpdateUser(data.name);
  };

  const handleUpdateUser = useCallback(
    async (name: string) => {
      try {
        setLoading(true);
        const { error } = await supabase
          .from("user_profiles")
          .update({ name: name })
          .eq("id", user?.id as string)
          .select();

        if (error) {
          throw error;
        }

        if (process.env.NODE_ENV === "production") {
          posthog.identify(user?.id, {
            name: name,
          });
        }

        onSuccess();
      } catch (e) {
        setErrorMessage("Unexpected error while updating user profile.");
      } finally {
        setLoading(false);
      }
    },
    [onSuccess, user?.id]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader className="flex flex-col">
        <h2 className="text-xl font-bold">Welcome</h2>
        <p className="text-sm font-normal text-default-500 mb-4">
          Let&apos;s get you all set up for a personalized experience
        </p>
      </ModalHeader>

      <ModalBody>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "Name is required",
            minLength: {
              value: 3,
              message: "Name must be at least 3 characters long",
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              size="lg"
              label="Name"
              autoFocus
              placeholder="What should we call you?"
              variant="bordered"
              isInvalid={fieldState.invalid}
              value={field.value}
              onValueChange={field.onChange}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
        {errorMessage && (
          <p className="text-sm text-center text-danger">{errorMessage}</p>
        )}
      </ModalBody>
      <ModalFooter className="flex flex-col">
        <Button
          type="submit"
          color="primary"
          className="w-full mt-4"
          isLoading={loading}
        >
          Continue
        </Button>
      </ModalFooter>
    </form>
  );
}
